import React, { useEffect, useState } from 'react';
import { TextField, Grid, Typography, Box, Divider, Button, Snackbar } from '@mui/material';
import { useWorkflow } from '../workflows/WorkflowContext';
import MuiAlert from '@mui/material/Alert';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// Validation schema
const schema = yup
  .object({
    title: yup.string().required('Workflow title is required'),
    description: yup.string().required('Process Description is required'),
    instructions: yup.string().required('Process Instructions are required'),
    video_demo: yup.string().url('Must be a valid URL').nullable()
  })
  .required();

const Setup = ({ onNextStep }) => {
  const { workflowData, updateWorkflowData, updateFormValidity, workflowMode, saveWorkflow } = useWorkflow();
  const [videoPreview, setVideoPreview] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    watch
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      title: workflowData.title || '',
      description: workflowData.description || '',
      instructions: workflowData.process_info?.instructions || '',
      video_demo: workflowData.process_info?.video_demo || ''
    }
  });

  useEffect(() => {
    reset({
      title: workflowData.title || '',
      description: workflowData.description || '',
      instructions: workflowData.process_info?.instructions || '',
      video_demo: workflowData.process_info?.video_demo || ''
    });
  }, [workflowData, reset]);

  // Watch for changes in form fields
  const watchedFields = watch();

  // Update workflow data on field change
  useEffect(() => {
    const updateWorkflow = () => {
      updateWorkflowData({
        title: watchedFields.title,
        description: watchedFields.description,
        process_info: {
          ...workflowData.process_info,
          instructions: watchedFields.instructions,
          video_demo: watchedFields.video_demo
        }
      });
    };

    // Debounce the update to avoid too frequent updates
    const timeoutId = setTimeout(updateWorkflow, 500);

    return () => clearTimeout(timeoutId);
  }, [watchedFields, updateWorkflowData, workflowData.process_info]);

  useEffect(() => {
    updateFormValidity('setup', isValid);
  }, [isValid, updateFormValidity]);

  // Handle video preview
  useEffect(() => {
    const videoLink = watchedFields.video_demo;
    if (videoLink) {
      if (videoLink.includes('youtube.com') || videoLink.includes('youtu.be')) {
        const videoId = videoLink.split('v=')[1]?.split('&')[0] || videoLink.split('/').pop();
        setVideoPreview(`https://www.youtube.com/embed/${videoId}`);
      } else if (videoLink.includes('loom.com')) {
        const loomId = videoLink.split('/').pop();
        setVideoPreview(`https://www.loom.com/embed/${loomId}`);
      } else {
        setVideoPreview('');
      }
    } else {
      setVideoPreview('');
    }
  }, [watchedFields.video_demo]);

  const onSubmit = async (data) => {
    console.log('Form submitted:', data);
    updateWorkflowData(data);

    if (workflowMode === 'create') {
      onNextStep();
    } else if (workflowMode === 'edit') {
      try {
        await saveWorkflow();
        console.log('Changes saved successfully');
        setSnackbarOpen(true);
        // Optionally, you can show a success message to the user here
      } catch (error) {
        console.error('Error saving changes:', error);
        // Optionally, you can show an error message to the user here
      }
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', margin: '20px 20px' }}>
      <Typography variant="h4" gutterBottom>
        {workflowMode === 'edit' ? 'Setup details' : 'Setup'}
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        {workflowMode === 'edit' ? 'Update your workflow details' : 'Add your workflow details'}
      </Typography>
      <Divider />
      <Box sx={{ margin: '20px 0px' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h5">Workflow Name</Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Give your new workflow a name
              </Typography>
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    placeholder="e.g., Customer Onboarding Process"
                    error={!!errors.title}
                    helperText={errors.title?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">Description</Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Enter a brief description of the workflow and the goal
              </Typography>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    placeholder="Describe the main steps and purpose of this workflow"
                    error={!!errors.description}
                    helperText={errors.description?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">Instructions</Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Detailed step-by-step instructions for executing the workflow
              </Typography>
              <Controller
                name="instructions"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    minRows={5}
                    maxRows={20}
                    placeholder={`1. Start by...\n2. Next, proceed to...\n3. Finally, ensure that...`}
                    error={!!errors.instructions}
                    helperText={errors.instructions?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">Video Demonstration</Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Add a Loom or YouTube video link to visually guide Basepilot through the process
              </Typography>
              <Controller
                name="video_demo"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    placeholder="https://www.loom.com/share/your-video-id or https://youtu.be/your-video-id"
                    error={!!errors.video_demo}
                    helperText={errors.video_demo?.message}
                  />
                )}
              />
            </Grid>
            {videoPreview && (
              <Grid item xs={12}>
                <iframe
                  width="100%"
                  height="315"
                  src={videoPreview}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Video Preview"
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!isValid}
                sx={{
                  mt: 2,
                  backgroundColor: '#1a202c',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#2d3748'
                  },
                  '&:disabled': {
                    backgroundColor: '#E2E8F0',
                    color: '#A0AEC0'
                  }
                }}
              >
                {workflowMode === 'edit' ? 'Save Changes' : 'Next'}
              </Button>
            </Grid>
          </Grid>
        </form>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
            Changes saved successfully!
          </MuiAlert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default Setup;
