import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Toolbar, useMediaQuery } from '@mui/material';

// project import
import Drawer from './Drawer';
import Header from './Header';
import navigation from 'menu-items';
import Breadcrumbs from 'components/@extended/Breadcrumbs';
//import { withAuthInfo } from '@propelauth/react'

// types
import { openDrawer } from 'store/reducers/menu';

//testing
/*
const API_URL = process.env.REACT_APP_API_URL; // Adjust this URL to your Flask API's URL
async function whoAmI(accessToken) {
  return fetch(`${API_URL}/api/whoami`, {
      method: 'GET',
      headers: {
          Authorization: `Bearer ${accessToken}`,
      },
  }).then((res) => res.json())
}
*/

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
  const dispatch = useDispatch();

  const { drawerOpen } = useSelector((state) => state.menu);
  //const [data, setData] = useState(null); // State to hold the data from the backend
  //const [serverResponse, setServerResponse] = useState(undefined) //auth variables

  //const API_URL = process.env.REACT_APP_API_URL; // Adjust this URL to your Flask API's URL

  // drawer toggler
  const [open, setOpen] = useState(drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  // set media wise responsive drawer
  useEffect(() => {
    setOpen(!matchDownLG);
    dispatch(openDrawer({ drawerOpen: !matchDownLG }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          mt: '60px', // Adjust this value based on your header height
          width: '100%', // Adjust based on drawer width
          height: 'calc(100vh - 60px)', // Subtract header height
          display: 'flex',
          overflow: 'hidden',
          flexDirection: 'column'
        }}
      >
        {' '}
        <Breadcrumbs navigation={navigation} title />
        <Box sx={{ flexGrow: 1 }}>
          <Outlet />
        </Box>{' '}
      </Box>
    </Box>
  );
};

export default MainLayout;
