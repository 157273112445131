import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import Setup from 'pages/create-workflow/WorkflowSetup';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

// render - browser
const BrowserPage = Loadable(lazy(() => import('pages/browser/BrowserPage')));

// render - runs
const Runs = Loadable(lazy(() => import('pages/runs/index')));

// render - apikeys
const APIKeys = Loadable(lazy(() => import('pages/api_keys/index')));

// render - workflows
const Workflows = Loadable(lazy(() => import('pages/workflows/index')));

// render - createWorkflow
const WorkflowCreate = Loadable(lazy(() => import('pages/create-workflow/index')));
const CreateWorkflow = Loadable(lazy(() => import('pages/create-workflow/CreateWorkflow')));
//const Tools = Loadable(lazy(() => import('pages/create-workflow/WorkflowTools')));

// render - workflow details page
const Flow = Loadable(lazy(() => import('pages/workflows/WorkflowPage')));
// render - workflow details page
const Workflow = Loadable(lazy(() => import('pages/workflows/Workflow')));

const WorkflowEdit = Loadable(lazy(() => import('pages/workflows/WorkflowEdit')));
const WorkflowBuild = Loadable(lazy(() => import('pages/workflows/WorkflowBuild')));

const WorkflowBase = Loadable(lazy(() => import('pages/workflows/WorkflowBase')));

// render - workflow runs page
const WorkflowRuns = Loadable(lazy(() => import('pages/workflows/WorkflowRuns')));
const RunDetails = Loadable(lazy(() => import('pages/workflows/RunDetails')));
const Run = Loadable(lazy(() => import('pages/workflows/Run')));
const WorkflowRun = Loadable(lazy(() => import('pages/workflows/WorkflowRun')));

// render - userdetails
const UserDetailsPage = Loadable(lazy(() => import('pages/user/UserDetails')));

// render - sample page
const SamplePage = Loadable(lazy(() => import('pages/extra-pages/SamplePage')));

// render - utilities
const Typography = Loadable(lazy(() => import('pages/components-overview/Typography')));
const Color = Loadable(lazy(() => import('pages/components-overview/Color')));
const Shadow = Loadable(lazy(() => import('pages/components-overview/Shadow')));
const AntIcons = Loadable(lazy(() => import('pages/components-overview/AntIcons')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <Workflows />
    },
    {
      path: '/workflows',
      element: <Workflows />
    },
    {
      path: 'workflows/:workflow_uuid',
      element: <Workflow />
    },
    {
      path: 'workflows/:workflow_uuid/:runId',
      element: <Run />
    },
    {
      path: 'workflows/:workflow_uuid/edit',
      element: <WorkflowEdit />
    },
    {
      path: 'color',
      element: <Color />
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'default',
          element: <DashboardDefault />
        }
      ]
    },
    {
      path: 'sample-page',
      element: <SamplePage />
    },
    {
      path: 'shadow',
      element: <Shadow />
    },
    {
      path: 'typography',
      element: <Typography />
    },
    {
      path: 'icons/ant',
      element: <AntIcons />
    },
    {
      path: 'browser',
      element: <BrowserPage />
    },
    {
      path: 'runs',
      element: <Runs />
    },
    {
      path: 'api_keys',
      element: <APIKeys />
    },
    {
      path: 'workflows',
      element: <Workflows />
    },
    {
      path: 'workflow/:id',
      element: <Flow />
    },
    {
      path: 'create-workflow',
      element: <WorkflowCreate />
    },
    {
      path: 'create-workflow-rework',
      element: <CreateWorkflow />
    },

    {
      path: '/run-details/:runId',
      element: <RunDetails />
    },
    {
      path: 'workflowbrowser/:workflow_uuid/edit',
      element: <WorkflowEdit />
    },
    {
      path: 'workflows/:workflow_uuid/*', // Changed to handle nested routing
      element: <WorkflowBase />,
      children: [
        {
          path: 'build',
          element: <WorkflowBuild /> // Handle the build tab
        },
        {
          path: 'setup',
          element: <Setup /> // Handle the build tab
        },
        {
          path: 'triggers',
          element: <WorkflowBuild /> // Handle the build tab
        },
        {
          path: 'tools',
          element: <WorkflowBuild /> // Handle the build tab
        },
        {
          path: '*',
          element: <WorkflowEdit /> // Default to build if no other route matches
        }
      ]
    },
    {
      path: 'user',
      element: <UserDetailsPage />
    }
  ]
};

export default MainRoutes;
