import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { updateWorkflow, createWorkflowRework, getWorkflow } from '../../services/api';
import { withAuthInfo } from '@propelauth/react';
import { useNavigate } from 'react-router-dom';

const WorkflowContext = createContext();

export const WorkflowProvider = withAuthInfo(({ children, initialWorkflowId = null, mode = 'create', ...props }) => {
  const [workflowId, setWorkflowId] = useState(initialWorkflowId);
  const [workflowData, setWorkflowData] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [formValidity, setFormValidity] = useState({
    setup: false,
    triggers: false,
    tools: false
  });
  const [workflowMode, setWorkflowMode] = useState(mode);
  const navigate = useNavigate();
  const authId = props.user.userId;

  useEffect(() => {
    if (workflowId && workflowMode === 'edit') {
      const fetchWorkflowData = async () => {
        try {
          const data = await getWorkflow(workflowId);
          setWorkflowData(data);
        } catch (err) {
          setError('Failed to fetch workflow data. Please try again.');
          console.error('Error fetching workflow:', err);
        }
      };
      fetchWorkflowData();
    }
  }, [workflowId, workflowMode]);

  const updateWorkflowData = useCallback((updates) => {
    setWorkflowData((prevData) => ({ ...prevData, ...updates }));
  }, []);

  const updateFormValidity = useCallback((page, isValid) => {
    setFormValidity((prev) => ({ ...prev, [page]: isValid }));
  }, []);

  const isNextButtonEnabled = useCallback(() => {
    switch (currentPage) {
      case 1:
        return formValidity.setup;
      case 2:
        return formValidity.triggers;
      case 3:
        return formValidity.tools;
      default:
        return false;
    }
  }, [currentPage, formValidity]);

  const saveWorkflow = useCallback(async () => {
    setIsSaving(true);
    setError(null);
    console.log('Workflow data from saveWorkflow:', workflowData);

    try {
      let response;
      console.log('workflowID:', workflowId);

      if (workflowId) {
        console.log('Updating existing workflow with ID:', workflowId);
        console.log('Uncleaned data:', workflowData);
        const { data, steps, edges, message, ...updateWorkflowData } = workflowData;
        console.log('Data saving to the workflow:', updateWorkflowData);
        response = await updateWorkflow(workflowId, updateWorkflowData);
      } else {
        console.log('Creating a new workflow with title:', workflowData.title);
        console.log('authId:', authId);
        const createWorkflowData = {
          title: workflowData.title,
          description: workflowData.description,
          process_info: {
            instructions: workflowData.process_info?.instructions,
            video_demo: workflowData.process_info?.video_demo
          }
        };
        response = await createWorkflowRework(authId, createWorkflowData);
        console.log('Data returned from create:', response);
      }

      const updatedWorkflow = response.data;

      if (updatedWorkflow) {
        if (!workflowId) {
          setWorkflowId(updatedWorkflow.uuid);
        }
        setWorkflowData(updatedWorkflow);
        console.log('New workflow data:', updatedWorkflow);
      } else {
        console.error('No data returned from the server');
        setError('Failed to save workflow. No data returned from the server.');
      }
    } catch (err) {
      setError('Failed to save workflow. Please try again.');
      console.error('Error saving workflow:', err);
    } finally {
      setIsSaving(false);
    }
  }, [workflowId, workflowData, authId]);

  const submitWorkflow = useCallback(async () => {
    setIsSaving(true);
    setError(null);
    try {
      const submittedWorkflow = await updateWorkflow(workflowId, { ...workflowData, status: 'Submitted' });
      setWorkflowData(submittedWorkflow);
      navigate('/workflows');
    } catch (err) {
      setError('Failed to submit workflow. Please try again.');
      console.error('Error submitting workflow:', err);
    } finally {
      setIsSaving(false);
    }
  }, [workflowId, workflowData, navigate]);

  const nextPage = useCallback(() => {
    if (currentPage < 3 && isNextButtonEnabled()) {
      setCurrentPage((prevPage) => prevPage + 1);
    } else if (currentPage === 3) {
      submitWorkflow();
    }
  }, [currentPage, isNextButtonEnabled, submitWorkflow]);

  const prevPage = useCallback(() => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  }, [currentPage]);

  const saveChanges = useCallback(async () => {
    try {
      await updateWorkflow(workflowId, workflowData);
      navigate('/workflows');
    } catch (err) {
      setError('Failed to save changes. Please try again.');
      console.error('Error saving changes:', err);
    }
  }, [workflowId, workflowData, navigate]);

  const value = {
    workflowId,
    workflowData,
    updateWorkflowData,
    saveWorkflow,
    submitWorkflow,
    saveChanges,
    isSaving,
    error,
    setWorkflowId,
    currentPage,
    nextPage,
    prevPage,
    updateFormValidity,
    isNextButtonEnabled,
    workflowMode
  };

  return <WorkflowContext.Provider value={value}>{children}</WorkflowContext.Provider>;
});

export const useWorkflow = () => useContext(WorkflowContext);
