// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import { withAuthInfo, useRedirectFunctions } from '@propelauth/react';
import AuthTokenProvider from './pages/user/AuthTokenProvider'; // Import the AuthTokenProvider

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = withAuthInfo((props) => {
  const { redirectToLoginPage } = useRedirectFunctions();

  if (props.isLoggedIn) {
    return (
      <ThemeCustomization>
        <ScrollTop>
          <Routes />
          <AuthTokenProvider /> {/* This will set the access token */}
        </ScrollTop>
      </ThemeCustomization>
    );
  } else {
    return redirectToLoginPage();
  }
});

export default App;
